<template>
    <AdminPanelTemplate>
        <template #breadcrumbs>
            <Breadcrumbs :items="[{ title: 'Inventories', to: useRoute().build(Inventory.routes().index) }, { title: 'Inventory Details' }]" />
        </template>
        <template #content>
            <v-card class="ma-3">
                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.inventory_code" label="Inventory Code" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.property.data.property_code" label="Property Code" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.year" label="Year" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.inventory_type.data.name" label="Inventory Type Name" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.inventory_view.data.carbon_inventory_tco2e" label="Total Carbon (tCO2e)" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.inventory_view.data.carbon_stock_tco2e" label="Carbon Stock (tCO2e)" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.inventory_view.data.carbon_buffer_tco2e" label="Carbon Buffer (tCO2e)" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.penalty" label="Carbon Loss" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.penalty_note" label="Additional Information" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.inventory_view.data.count_of_blocks" label="Blocks (Count)" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.inventory_view.data.carbon_stock_allocated" label="Stock allocated (tCO2e)" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.inventory_view.data.carbon_stock_available" label="Stock available (tCO2e)" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.inventory_view.data.status" label="Status" />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </template>
    </AdminPanelTemplate>
</template>

<script setup lang="ts">
import { Inventory } from '@app/land-hold/inventory/models/Inventory';
import { useRoute } from '@shared/composables/useRoute';

const page = Inventory.routes().show.page;
</script>
