<template>
    <v-row>
        <v-col>
            <v-row>
                <v-col>
                    <Autocomplete v-model:selected-items="includeYearSelectedFilters" hide-details field-name="year" :return-object="false" :items="yearRange" label="Year" multiple />
                </v-col>
                <v-col>
                    <Autocomplete v-model:selected-items="includeInventoryTypeNewFilters" hide-details item-title="name" field-name="''" :items="inventoryTypes" label="Type" multiple :loading="fetchingInventoryTypes" />
                </v-col>
                <v-col cols="auto">
                    <v-btn color="primary" :loading="fetchingInventories || fetchingInventoryTypes" @click="fetchInventoryStats">Apply Filters</v-btn>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12">
            <BarChart v-if="chartData" id="inventory-stats-chart" :data="chartData" :options="options" />
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import type { ChartData, ChartOptions } from 'chart.js';
import { type IInventoryType, InventoryType } from '@app/land-hold/inventory-type/models/InventoryType';
import { useFetchListData } from '@shared/composables/useFetchListData';
import { useRoute } from '@shared/composables/useRoute';
import { useUtility } from '@shared/composables/useUtility';
import { Inventory } from '@app/land-hold/inventory/models/Inventory';
import type { InventoryBufferPenaltyStat } from '@app/land-hold/inventory/contracts/InventoryBufferPenaltyStat';
import type { ListResponse } from '@shared/types/ApiResponse';

const options = reactive<ChartOptions<'bar'>>({
    plugins: {
        title: {
            text: 'Inventory Buffer/Carbon Loss Stats',
        },
    },
    indexAxis: 'y',
}) as ChartOptions<'bar'>;

const chartData = ref<ChartData<'bar', number[]> | null>(null);
const { getYearRange } = useUtility();
const bufferStats: Ref<InventoryBufferPenaltyStat[]> = ref([]);
const fetchingInventories = ref(false);
// TODO: this operation must be moved to the repository
const fetchInventoryStats = () => {
    fetchingInventories.value = true;
    window.axios
        .get<ListResponse<InventoryBufferPenaltyStat>>(useRoute().build(Inventory.routes().stats.buffer), {
            params: {
                year: includeYearSelectedFilters.value,
                inventory_type: includeInventoryTypeNewFilters.value,
            },
        })
        .then((response) => {
            bufferStats.value = response.data.data;
            chartData.value = statsToChartData(bufferStats.value);
        })
        .catch((error: unknown) => {
            console.error(error);
        })
        .finally(() => {
            fetchingInventories.value = false;
        });
};

function statsToChartData(stats: InventoryBufferPenaltyStat[]): ChartData<'bar', number[]> {
    return {
        labels: stats.map((stat) => stat.inventory_type_name),
        datasets: [
            {
                label: 'Buffer',
                data: stats.map((stat) => stat.buffer),
                backgroundColor: 'rgba(105,170,116, 1)',
            },
            {
                label: 'Carbon Loss',
                data: stats.map((stat) => stat.penalty),
                backgroundColor: 'rgba(227,111,89, 1)',
            },
        ],
    };
}

const yearRange = getYearRange();
const includeYearSelectedFilters = ref<number[]>([]);
const includeInventoryTypeNewFilters = ref<number[]>([]);

const { data: inventoryTypes, processing: fetchingInventoryTypes } = useFetchListData<IInventoryType>(useRoute().build(InventoryType.routes().index), 'appSection@inventoryType::IndexInventoryTypesPage');

onBeforeMount(() => {
    fetchInventoryStats();
});
</script>

<style scoped lang="scss"></style>
