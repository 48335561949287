import { Model } from '@shared/models/Model';
import type { DataTableHeaders } from '@shared/types/Vuetify';
import type { IModel } from '@shared/types/Model';

export interface IPublicBlock extends IModel {
    airtable_id: string;
    airtable_transaction_id: string;
    transaction_id: string;
    airtable_inventory_id: string;
    inventory_id: string;
    first_carbon_value: string;
    carbon_block_tco2e: string;
    last_carbon_value: string;
    block_serial_code: string;
}

export class PublicBlock extends Model implements IPublicBlock {
    airtable_id!: string;
    airtable_transaction_id!: string;
    transaction_id!: string;
    airtable_inventory_id!: string;
    inventory_id!: string;
    first_carbon_value!: string;
    carbon_block_tco2e!: string;
    last_carbon_value!: string;
    block_serial_code!: string;

    public constructor(o: IPublicBlock) {
        super(o);
        Object.assign(this, o);
    }

    public static override headers(): DataTableHeaders<IPublicBlock> {
        return [
            {
                title: 'Block Serial Code',
                key: 'block_serial_code',
                hasTooltip: () => ({
                    text: 'A unique identifier for a block of carbon offset that can be linked to a specific inventory',
                }),
            },
            {
                title: 'First Carbon Value',
                key: 'first_carbon_value',
                align: 'center',
                hasTooltip: () => ({
                    text: 'The starting carbon value from the inventory, indicating the portion included in the transaction',
                }),
            },
            {
                title: 'Carbon (tCO2e)',
                key: 'carbon_block_tco2e',
                align: 'center',
                hasTooltip: () => ({
                    text: 'The total amount of carbon offset involved in the block measured in metric tons of CO₂ equivalent',
                }),
            },
            {
                title: 'Last Carbon Value',
                key: 'last_carbon_value',
                align: 'center',
                hasTooltip: () => ({
                    text: 'The ending carbon value from the inventory, specifying the portion included in the transaction',
                }),
            },
            { title: 'Created At', key: 'created_at', date: true },
            { title: 'Updated At', key: 'updated_at', date: true },
        ];
    }
}
