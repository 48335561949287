import { useForm } from '@shared/composables/useForm';
import { useRoute } from '@shared/composables/useRoute';
import { usePayload } from '@shared/composables/usePayload';
import { useInjectErrorState } from '@shared/composables/useProvideErrorState';
import { useWithValidationConfig } from '@shared/composables/useWithValidationConfig';
import { type IInventory, Inventory } from '@app/land-hold/inventory/models/Inventory';
import type { FormOptions, InertiaFormData } from '@shared/types/Form';

export function useInventoryRepository<T extends IInventory = IInventory>() {
    const command = () => {
        const generate = <T extends InertiaFormData>(data: T, options?: FormOptions) => {
            const hasError = useInjectErrorState();
            const form = useForm<T>(data).precognition(
                Inventory.routes().store,
                useWithValidationConfig(hasError, () => form, options),
            );

            function execute(options?: FormOptions) {
                form.transform((data) => usePayload<T>(data)).submit(options);
            }

            return { form, execute };
        };

        const update = (id: string, data: T, options?: FormOptions) => {
            const hasError = useInjectErrorState();
            const form = useForm<T>(data).precognition(
                {
                    url: useRoute().build(Inventory.routes().update, {
                        id,
                    }),
                    method: Inventory.routes().update.method,
                },
                useWithValidationConfig(hasError, () => form, options),
            );

            function execute(options?: FormOptions) {
                form.transform((data) =>
                    usePayload<T>(data, {
                        nullable: ['penalty'],
                    }),
                ).submit(options);
            }

            return { form, execute };
        };

        const destroy = <U extends { id: string }>(id: string, options?: FormOptions) => {
            const hasError = useInjectErrorState();
            const form = useForm<U>({ id } as U).precognition(
                {
                    url: useRoute().build(Inventory.routes().destroy, {
                        id,
                    }),
                    method: Inventory.routes().destroy.method,
                },
                useWithValidationConfig(hasError, () => form, options),
            );

            function execute(options?: FormOptions) {
                form.submit(options);
            }

            return { form, execute };
        };

        return {
            generate,
            update,
            destroy,
        };
    };

    const query = () => {
        const show = (id: string) => {
            return { endpoint: useRoute().build(Inventory.routes().show, { id }) };
        };

        return {
            show,
        };
    };

    return {
        command: command(),
        query: query(),
    };
}
