import { useForm } from '@shared/composables/useForm';
import { useRoute } from '@shared/composables/useRoute';
import { usePayload } from '@shared/composables/usePayload';
import { useInjectErrorState } from '@shared/composables/useProvideErrorState';
import { useWithValidationConfig } from '@shared/composables/useWithValidationConfig';
import { type IProject, Project } from '@app/land-hold/project/models/Project';
import type { FormOptions } from '@shared/types/Form';
import { useFetchListData } from '@shared/composables/useFetchListData.ts';

export function useProjectRepository<T extends IProject = IProject>() {
    const command = () => {
        const store = (data: T, options?: FormOptions) => {
            const hasError = useInjectErrorState();
            const form = useForm<T>(data).precognition(
                Project.routes().store,
                useWithValidationConfig(hasError, () => form, options),
            );
            function execute(options?: FormOptions) {
                form.transform((data) => usePayload<T>(data)).submit(options);
            }

            return { form, execute };
        };

        const update = (id: string, data: T, options?: FormOptions) => {
            const hasError = useInjectErrorState();
            const form = useForm<T>(data).precognition(
                {
                    url: useRoute().build(Project.routes().update, {
                        id,
                    }),
                    method: Project.routes().update.method,
                },
                useWithValidationConfig(hasError, () => form, options),
            );
            function execute(options?: FormOptions) {
                form.transform((data) => usePayload<T>(data, {
                    exclude: ['steward', 'properties_count', 'properties', 'approvalAuthority'],
                    nullable: ['sent_for_approval_at', 'end_date'],
                })).submit(options);
            }

            return { form, execute };
        };

        const destroy = <U extends { id: string }>(id: string, options?: FormOptions) => {
            const hasError = useInjectErrorState();
            const form = useForm<U>({ id } as U).precognition(
                {
                    url: useRoute().build(Project.routes().destroy, {
                        id,
                    }),
                    method: Project.routes().destroy.method,
                },
                useWithValidationConfig(hasError, () => form, options),
            );
            function execute(options?: FormOptions) {
                form.submit(options);
            }

            return { form, execute };
        };

        return {
            store,
            update,
            destroy,
        };
    };

    const query = () => {
        const show = (id: string) => {
            return { endpoint: useRoute().build(Project.routes().show, { id }) };
        };

        const list = () => {
            return useFetchListData<IProject>(useRoute().build(Project.routes().index), 'appSection@project::IndexProjectsPage')
        }

        const listAllowedFor = (stewardId: string) => {
            return useFetchListData<IProject>(useRoute().build(Project.routes().index,
                {
                    'search': 'approval_status:' + Project.getPendingStatus() + ';steward_id:' + stewardId,
                    'searchFields': 'approval_status:=;steward_id:=',
                    'searchJoin': 'and',
                    'orderBy': 'start_date',
                    'sortedBy': 'desc',
                }), 'appSection@project::IndexProjectsPage')
        }

        return {
            show,
            list,
            listAllowedFor,
        };
    };

    return {
        command: command(),
        query: query(),
    };
}
