import { Model } from '@shared/models/Model';
import { Inventory, type InventoryStatus } from './Inventory';
import { useInventoryRepository } from '@app/land-hold/inventory/repositories/useInventoryRepository';
import type { DotNotationOf, ChipConfig, DataTableHeaders } from '@shared/types/Vuetify';
import type { IModel } from '@shared/types/Model';

export interface IInventoryView extends IModel {
    inventory_code: string;
    year: number;
    penalty: number;
    buffer_percentage: number;
    property_code: string;
    inventory_type: string;
    carbon_inventory_tco2e: string;
    carbon_stock_tco2e: string;
    carbon_buffer_tco2e: string;
    count_of_blocks: string;
    carbon_stock_allocated: string;
    carbon_stock_available: string;
    priority: number;
    status: InventoryStatus;
    start_date: string;
    end_date: string;
    interval_start_date: string;
    interval_end_date: string;
    active_days: number;
    days_in_year: number;
    active_fraction: number;
    total_carbon_full_year: number;
}

export class InventoryView extends Model implements IInventoryView {
    public inventory_code!: string;
    public year!: number;
    public penalty!: number;
    public buffer_percentage!: number;
    public property_code!: string;
    public inventory_type!: string;
    public carbon_inventory_tco2e!: string;
    public carbon_stock_tco2e!: string;
    public carbon_buffer_tco2e!: string;
    public count_of_blocks!: string;
    public carbon_stock_allocated!: string;
    public carbon_stock_available!: string;
    public priority!: number;
    public status!: InventoryStatus;
    public start_date!: string;
    public end_date!: string;
    public interval_start_date!: string;
    public interval_end_date!: string;
    public active_days!: number;
    public days_in_year!: number;
    public active_fraction!: number;
    public total_carbon_full_year!: number;

    public constructor(o: IInventoryView) {
        super(o);
        Object.assign(this, o);
    }

    public static override chips(): DotNotationOf<IInventoryView>[] {
        return ['status', 'year'];
    }

    public static override chipConfig(field: DotNotationOf<IInventoryView>, item: IInventoryView): ChipConfig {
        switch (field) {
            case 'status':
                return { size: 'small', color: Inventory.getStatusColor(item.status) };
            default:
                return { size: 'small' };
        }
    }

    public static override headers(): DataTableHeaders<IInventoryView> {
        return [
            { title: 'Inventory Code', key: 'inventory_code', link: (item: IInventoryView) => useInventoryRepository().query.show(item.id).endpoint },
            { title: 'Year', key: 'year' },
            { title: 'Inventory Type Name', key: 'inventory_type' },
            { title: 'Total Carbon (tCO2e)', key: 'carbon_inventory_tco2e', align: 'center' },
            { title: 'Carbon Stock (tCO2e)', key: 'carbon_stock_tco2e', align: 'center' },
            { title: 'Carbon Buffer (tCO2e)', key: 'carbon_buffer_tco2e', align: 'center' },
            { title: 'Blocks (Count)', key: 'count_of_blocks', align: 'center' },
            { title: 'Stock allocated (tCO2e)', key: 'carbon_stock_allocated', align: 'center' },
            { title: 'Stock available (tCO2e)', key: 'carbon_stock_available', align: 'center' },
            { title: 'Priority', key: 'priority', align: 'center' },
            { title: 'Status', key: 'status' },
        ];
    }

    public static manageInventoryPageHeaders(): DataTableHeaders<IInventoryView> {
        return [
            { title: 'Inventory Code', key: 'inventory_code', sortable: false, link: (item: IInventoryView) => useInventoryRepository().query.show(item.id).endpoint },
            { title: 'Year', key: 'year', sortable: false },
            { title: 'Inventory Type Name', key: 'inventory_type' },
            { title: 'Total Carbon (tCO2e)', key: 'carbon_inventory_tco2e', sortable: false, align: 'center' },
            { title: 'Carbon Stock (tCO2e)', key: 'carbon_stock_tco2e', sortable: false, align: 'center' },
            { title: 'Buffer Percentage', key: 'buffer_percentage', align: 'center' },
            { title: 'Carbon Buffer (tCO2e)', key: 'carbon_buffer_tco2e', sortable: false, align: 'center' },
            { title: 'Carbon Loss', key: 'penalty', align: 'center' },
            { title: 'Blocks (Count)', key: 'count_of_blocks', sortable: false, align: 'center' },
            { title: 'Stock allocated (tCO2e)', key: 'carbon_stock_allocated', sortable: false, align: 'center' },
            { title: 'Stock available (tCO2e)', key: 'carbon_stock_available', sortable: false, align: 'center' },
            { title: 'Priority', key: 'priority', align: 'center' },
            { title: 'Status', key: 'status', sortable: false },
            { title: 'Start Date', key: 'start_date', date: true, sortable: false },
            { title: 'End Date', key: 'end_date', date: true, sortable: false },
            { title: 'Active Start Date', key: 'interval_start_date', date: true, sortable: false },
            { title: 'Active End Date', key: 'interval_end_date', date: true, sortable: false },
            { title: 'Active Days', key: 'active_days', align: 'center', sortable: false },
            { title: 'Days in Year', key: 'days_in_year', align: 'center', sortable: false },
            { title: 'Fraction of Active Day', key: 'active_fraction', align: 'center', sortable: false },
            { title: 'Total Carbon Full Year (tCO2e)', key: 'total_carbon_full_year', align: 'center', sortable: false },
        ];
    }
}
