import { type IProperty, Property } from '@app/land-hold/property/models/Property';
import { useForm } from '@shared/composables/useForm';
import { useRoute } from '@shared/composables/useRoute';
import { usePayload } from '@shared/composables/usePayload';
import { useInjectErrorState } from '@shared/composables/useProvideErrorState';
import { useWithValidationConfig } from '@shared/composables/useWithValidationConfig';
import { type IPartition, Partition } from '@app/land-hold/partition/models/Partition';
import type { FormOptions } from '@shared/types/Form';
import { useFetchListData } from '@shared/composables/useFetchListData.ts';

export function usePropertyRepository<T extends IProperty = IProperty>() {
    const command = () => {
        const store = (data: T, options?: FormOptions) => {
            const hasError = useInjectErrorState();
            const form = useForm<T>(data).precognition(
                Property.routes().store,
                useWithValidationConfig(hasError, () => form, options),
            );

            function execute(options?: FormOptions) {
                form.transform((data) =>
                    usePayload<T>(data, {
                        exclude: Property.create(data).hidden(),
                        nullable: ['start_date', 'end_date', 'buffer', 'carbon_loss', 'additional_info'],
                    }),
                ).submit(options);
            }

            return { form, execute };
        };

        const update = (id: string, data: T, options?: FormOptions) => {
            const hasError = useInjectErrorState();
            const form = useForm<T>(data).precognition(
                {
                    url: useRoute().build(Property.routes().update, {
                        id,
                    }),
                    method: Property.routes().update.method,
                },
                useWithValidationConfig(hasError, () => form, options),
            );

            function execute(options?: FormOptions) {
                form.transform((data) =>
                    usePayload<T>(data, {
                        exclude: Property.create(data).hidden(),
                        nullable: ['start_date', 'end_date', 'buffer', 'carbon_loss', 'additional_info'],
                    }),
                ).submit(options);
            }

            return { form, execute };
        };

        const destroy = <U extends { id: string }>(id: string, options?: FormOptions) => {
            const hasError = useInjectErrorState();
            const form = useForm<U>({ id } as U).precognition(
                {
                    url: useRoute().build(Property.routes().destroy, {
                        id,
                    }),
                    method: Property.routes().destroy.method,
                },
                useWithValidationConfig(hasError, () => form, options),
            );

            function execute(options?: FormOptions) {
                form.submit(options);
            }

            return { form, execute };
        };

        // TODO: this api endpoint needs to be moved to the property container
        // it is post => partitions currently but
        // the endpoint should be post => properties/{property_id}/partitions
        const storePartition = <T extends IPartition>(data: T, options?: FormOptions) => {
            const hasError = useInjectErrorState();
            const form = useForm<T>(data).precognition(
                Partition.routes().store,
                useWithValidationConfig(hasError, () => form, options),
            );

            function execute(options?: FormOptions) {
                form.transform((data) => usePayload<T>(data)).submit(options);
            }

            return { form, execute };
        };

        // TODO: the api endpoint for this, allows anyone to delete a partition
        // without checking if the partition belongs to the property
        // this is a security issue that needs to be addressed
        // the endpoint should be delete => properties/{property_id}/partitions/{partition_id}
        const destroyPartition = <U extends { id: string }>(id: string, options?: FormOptions) => {
            const hasError = useInjectErrorState();
            const form = useForm<U>({ id } as U).precognition(
                {
                    url: useRoute().build(Partition.routes().destroy, {
                        id,
                    }),
                    method: Partition.routes().destroy.method,
                },
                useWithValidationConfig(hasError, () => form, options),
            );

            function execute(options?: FormOptions) {
                form.submit(options);
            }

            return { form, execute };
        };

        return {
            store,
            update,
            destroy,
            storePartition,
            destroyPartition,
        };
    };

    const query = () => {
        const show = (id: string) => {
            return { endpoint: useRoute().build(Property.routes().show, { id }) };
        };

        const indexPropertyPartitions = (id: string) => {
            return { endpoint: useRoute().build(Property.routes().partitions.index, { id }) };
        };

        const list = () => {
            return useFetchListData<IProperty>(route(Property.routes().index.name), 'appSection@property::IndexPropertiesPage');
        };

        const listAllowedFor = (stewardId: string) => {
            return useFetchListData<IProperty>(
                useRoute().build(Property.routes().index, {
                    search: 'steward_id:' + stewardId,
                    searchFields: 'steward_id:=',
                    orderBy: 'property_name',
                    sortedBy: 'asc',
                }),
                'appSection@property::IndexPropertiesPage',
            );
        };

        return {
            show,
            indexPropertyPartitions,
            list,
            listAllowedFor,
        };
    };

    return {
        command: command(),
        query: query(),
    };
}
