<template>
    <AdminPanelTemplate>
        <template #breadcrumbs>
            <Breadcrumbs :items="[{ title: 'Transactions', to: useRoute().build(PublicTransaction.routes().index) }, { title: 'Transaction Details' }]" />
        </template>
        <template #content>
            <v-row no-gutters class="fill-height mt-n16" align-content="space-between">
                <v-col cols="12" class="mt-16">
                    <v-card class="ma-3">
                        <v-tabs v-model="tab">
                            <v-tab value="one" rounded="0">Transaction</v-tab>
                            <v-tab value="two" rounded="0">Blocks</v-tab>
                        </v-tabs>
                        <v-card-text>
                            <v-tabs-window v-model="tab">
                                <v-tabs-window-item value="one">
                                    <v-row>
                                        <v-col>
                                            <v-card-title>Transaction Details</v-card-title>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-img v-show="displayUberLogo" src="@shared/assets/images/uber-logo.png" max-width="70" min-width="70" />
                                        </v-col>
                                    </v-row>
                                    <v-row align="start">
                                        <v-col cols="12" md="6">
                                            <v-row>
                                                <v-col>
                                                    <ReadOnlyTextField :value="page.props.data.transaction_code" label="Transaction Code" />
                                                </v-col>
                                                <v-col>
                                                    <ReadOnlyTextField v-show="page.props.data.certifier?.data.public" :value="page.props.data.certifier?.data.name" label="Certificate Owner" />
                                                </v-col>
                                                <v-responsive width="100%" />
                                                <v-col>
                                                    <ReadOnlyDateTextField :date="page.props.data.execution_date" label="Date" />
                                                </v-col>
                                                <v-col>
                                                    <ReadOnlyTextField :value="page.props.data.carbon_amount_tco2e" label="Carbon (tCO2e)" />
                                                </v-col>
                                                <v-responsive width="100%" />
                                                <v-col>
                                                    <ReadOnlyTextField :value="page.props.data.steward_name" label="Steward" />
                                                </v-col>
                                                <v-col>
                                                    <ReadOnlyTextField :value="page.props.data.type" label="Transaction Type Name" />
                                                </v-col>
                                                <v-responsive width="100%" />
                                                <v-col>
                                                    <ReadOnlyTextField :value="page.props.data.country" label="Country" />
                                                </v-col>
                                                <v-col>
                                                    <ReadOnlyTextField v-show="page.props.data.state" :value="page.props.data.state" label="State" />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <iframe v-show="page.props.data.certifier?.data.public" class="mapContainer" :src="page.props.data.certifier?.data.embed_url ?? ''" :allowfullscreen="true" />
                                        </v-col>
                                    </v-row>
                                </v-tabs-window-item>

                                <v-tabs-window-item value="two">
                                    <v-row>
                                        <v-col>
                                            <v-card-title>Blocks</v-card-title>
                                        </v-col>
                                    </v-row>
                                    <v-data-table :items="page.props.data.blocks.data" :headers="PublicBlock.headers()">
                                        <template v-for="column in dateColumns" :key="column.key" #[`item.${column.key}`]="{ item }">
                                            {{ dateUtil.toISO8601(getValue(item, column.key)) }}
                                        </template>

                                        <template v-for="col in tooltipColumns" :key="col.key" #[`header.${col.key}`]="{ column, isSorted, toggleSort, getSortIcon, sortBy }">
                                            <!-- https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/components/VDataTable/VDataTableHeaders.tsx -->
                                            <v-tooltip v-if="col.hasTooltip" :text="col.hasTooltip(column).text" :location="col.hasTooltip(column).location ?? 'top'" :max-width="250">
                                                <template #activator="{ props: tooltipProps }">
                                                    <div class="v-data-table-header__content">
                                                        <v-icon v-bind="tooltipProps" :icon="col.hasTooltip(column).icon ?? 'mdi-information'" :color="col.hasTooltip(column).iconColor ?? themeInstance.global.current.value.colors.primary" />
                                                        <div class="v-data-table-header__content">
                                                            <span @click="() => toggleSort(column)">{{ column.title }}</span>
                                                            <v-icon v-if="column.sortable" class="v-data-table-header__sort-icon" :icon="getSortIcon(column)" />
                                                            <div v-if="isSorted(column)" class="v-data-table-header__sort-badge">
                                                                {{ sortBy.findIndex((x) => x.key === column.key) + 1 }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </v-tooltip>
                                        </template>
                                    </v-data-table>
                                </v-tabs-window-item>
                            </v-tabs-window>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <ThePublicRegistryFooter />
                </v-col>
            </v-row>
        </template>
    </AdminPanelTemplate>
</template>

<script setup lang="ts">
import { useRoute } from '@shared/composables/useRoute';
import { PublicTransaction } from '@app/purchase/transaction/models/PublicTransaction';
import { PublicBlock } from '@app/purchase/block/models/PublicBlock';
import { useDate } from '@shared/composables/useDate';
import { useTheme } from 'vuetify';

const page = PublicTransaction.routes().show.page;

const tab = ref(null);
const dateColumns = computed(() => PublicBlock.headers().filter((column) => column.date));
const tooltipColumns = computed(() => PublicBlock.headers().filter((column) => column.hasTooltip));
const dateUtil = useDate();
const themeInstance = useTheme();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getValue(item: any, key: string) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return key.split('.').reduce((o, k) => o[k], item);
}

const displayUberLogo = computed(() => page.props.data.certifier?.data.name.toLowerCase() === 'uber');
</script>

<style lang="scss" scoped>
.mapContainer {
    height: 400px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
}
</style>
