import { type CreateRoute, type DestroyRoute, type EditRoute, type IndexRoute, type ShowRoute, type StoreRoute, type UpdateRoute } from '@shared/types/Route';
import { useInventoryRepository } from '@app/land-hold/inventory/repositories/useInventoryRepository';
import type { DataTableHeaders } from '@shared/types/Vuetify';
import { type IInventoryView } from '@app/land-hold/inventory/models/InventoryView';
import type { SingleObjectInclude } from '@shared/types/ApiResponse';
import type { IOwner } from '@app/land-hold/owner/models/Owner';
import { useOwnerRepository } from '@app/land-hold/owner/repositories/useOwnerRepository';
import type { InventoryStatus } from '@app/land-hold/inventory/models/Inventory';
import { Model } from '@shared/models/Model';

export interface IInventoryQueue extends IInventoryView {
    owner: SingleObjectInclude<IOwner>;
}

export class InventoryQueue extends Model implements IInventoryQueue {
    public owner!: SingleObjectInclude<IOwner>;

    public inventory_code!: string;
    public year!: number;
    public penalty!: number;
    public buffer_percentage!: number;
    public property_code!: string;
    public inventory_type!: string;
    public carbon_inventory_tco2e!: string;
    public carbon_stock_tco2e!: string;
    public carbon_buffer_tco2e!: string;
    public count_of_blocks!: string;
    public carbon_stock_allocated!: string;
    public carbon_stock_available!: string;
    public priority!: number;
    public status!: InventoryStatus;
    public start_date!: string;
    public end_date!: string;
    public interval_start_date!: string;
    public interval_end_date!: string;
    public active_days!: number;
    public days_in_year!: number;
    public active_fraction!: number;
    public total_carbon_full_year!: number;

    public constructor(o: IInventoryQueue) {
        super(o);
        Object.assign(this, o);
    }

    public static override headers(): DataTableHeaders<IInventoryQueue> {
        return [
            {
                title: 'Inventory Code',
                key: 'inventory_code',
                sortable: false,
                link: (item: IInventoryQueue) => useInventoryRepository().query.show(item.id).endpoint,
                fixed: true,
                width: 225.23,
                headerProps: { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } },
            },
            { title: 'Property', key: 'property_code', sortable: false },
            { title: 'Year', key: 'year', sortable: false },
            { title: 'Inventory Type Name', key: 'inventory_type', sortable: false },
            {
                title: 'Owner',
                key: 'owner.data.name',
                link: (item: IInventoryQueue) => useOwnerRepository().query.show(item.owner.data.id).endpoint,
                sortable: false,
            },
            { title: 'Total Carbon (tCO2e)', key: 'carbon_inventory_tco2e', align: 'center', sortable: false },
            { title: 'Carbon Stock (tCO2e)', key: 'carbon_stock_tco2e', align: 'center', sortable: false },
            { title: 'Buffer Percentage', key: 'buffer_percentage', align: 'center', sortable: false  },
            { title: 'Carbon Buffer (tCO2e)', key: 'carbon_buffer_tco2e', align: 'center', sortable: false },
            { title: 'Carbon Loss', key: 'penalty', align: 'center', sortable: false  },
            { title: 'Blocks (Count)', key: 'count_of_blocks', align: 'center', sortable: false },
            { title: 'Stock allocated (tCO2e)', key: 'carbon_stock_allocated', align: 'center', sortable: false },
            { title: 'Stock available (tCO2e)', key: 'carbon_stock_available', align: 'center', sortable: false },
            { title: 'Priority', key: 'priority', align: 'center', sortable: false },
            { title: 'Status', key: 'status', sortable: false },
            { title: 'Start Date', key: 'start_date', date: true, sortable: false },
            { title: 'End Date', key: 'end_date', date: true, sortable: false },
            { title: 'Active Start Date', key: 'interval_start_date', date: true, sortable: false },
            { title: 'Active End Date', key: 'interval_end_date', date: true, sortable: false },
            { title: 'Active Days', key: 'active_days', align: 'center', sortable: false },
            { title: 'Days in Year', key: 'days_in_year', align: 'center', sortable: false },
            { title: 'Fraction of Active Day', key: 'active_fraction', align: 'center', sortable: false },
            {
                title: 'Total Carbon Full Year (tCO2e)',
                key: 'total_carbon_full_year',
                align: 'center',
                sortable: false,
            },
        ];
    }

    public static override routes(): {
        index: IndexRoute<'inventories.queue.index', IInventoryQueue>;
        show: ShowRoute<'inventories.queue.show'>;
        destroy: DestroyRoute<'inventories.queue.destroy'>;
        create: CreateRoute<'inventories.queue.create'>;
        store: StoreRoute<'inventories.queue.store'>;
        edit: EditRoute<'inventories.queue.edit'>;
        update: UpdateRoute<'inventories.queue.update'>;
    } {
        return {
            index: {
                method: 'get',
                name: 'inventories.queue.index',
                datatableHeaders: () => InventoryQueue.headers(),
                page: usePage(),
            },
            show: { method: 'get', name: 'inventories.queue.show', page: usePage() },
            destroy: { method: 'delete', name: 'inventories.queue.destroy', page: usePage() },
            create: { method: 'get', name: 'inventories.queue.create', page: usePage() },
            store: { method: 'post', name: 'inventories.queue.store', page: usePage() },
            edit: { method: 'get', name: 'inventories.queue.edit', page: usePage() },
            update: { method: 'patch', name: 'inventories.queue.update', page: usePage() },
        };
    }
}
