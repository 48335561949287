import { Model } from '@shared/models/Model';
import { type AllInventoryIncludes, type IndexInventoryIncludes, routes } from '@app/land-hold/inventory/routes/routes';
import type { CsvStructure } from '@shared/composables/useDownloadCsv';
import { usePropertyRepository } from '@app/land-hold/property/repositories/usePropertyRepository';
import { useInventoryRepository } from '@app/land-hold/inventory/repositories/useInventoryRepository';
import type { DotNotationOf, ChipConfig, DataTableHeaders } from '@shared/types/Vuetify';
import type { IModel } from '@shared/types/Model';
import { useOwnerRepository } from '@app/land-hold/owner/repositories/useOwnerRepository';

export enum InventoryStatus {
    FullyAllocated = 'Fully Allocated',
    PartiallyAllocated = 'Partially Allocated',
    NotAllocated = 'Not Allocated',
}

export interface IInventory extends IModel {
    property_id: string;
    year: number;
    inventory_code: string;
    inventory_type_id: string;
    penalty: number | null;
    penalty_note: string | null;
    priority: number;
    start_date: string;
    end_date: string;
    interval_start_date: string;
    interval_end_date: string;
    active_days: number;
    days_in_year: number;
    active_fraction: number;
    total_carbon_full_year: number;
}

export class Inventory extends Model implements IInventory {
    public property_id!: string;
    public year!: number;
    public inventory_code!: string;
    public inventory_type_id!: string;
    public penalty!: number | null;
    public penalty_note!: string | null;
    public priority!: number;
    public start_date!: string;
    public end_date!: string;
    public interval_start_date!: string;
    public interval_end_date!: string;
    public active_days!: number;
    public days_in_year!: number;
    public active_fraction!: number;
    public total_carbon_full_year!: number;

    public constructor(o: IInventory) {
        super(o);
        Object.assign(this, o);
    }

    public static override chips(): DotNotationOf<AllInventoryIncludes>[] {
        return ['inventory_view.data.status', 'year'];
    }

    public static override chipConfig(field: DotNotationOf<AllInventoryIncludes>, item: AllInventoryIncludes): ChipConfig {
        let color;
        switch (field) {
            case 'inventory_view.data.status':
                if (!item.inventory_view) {
                    return { size: 'small' };
                }
                color = Inventory.getStatusColor(item.inventory_view.data.status);
                switch (item.inventory_view.data.status) {
                    case InventoryStatus.FullyAllocated:
                        return { size: 'small', color, text: item.inventory_view.data.status };
                    case InventoryStatus.PartiallyAllocated:
                        return { size: 'small', color, text: item.inventory_view.data.status };
                    case InventoryStatus.NotAllocated:
                        return { size: 'small', color, text: item.inventory_view.data.status };
                    default:
                        return { size: 'small', text: item.inventory_view.data.status };
                }
            default:
                return { size: 'small' };
        }
    }

    public static getStatusColor(status: InventoryStatus): string {
        switch (status) {
            case InventoryStatus.NotAllocated:
                return 'error';
            case InventoryStatus.FullyAllocated:
                return 'success';
            case InventoryStatus.PartiallyAllocated:
                return 'warning';
            default:
                return 'info';
        }
    }

    public static override headers(): DataTableHeaders<IndexInventoryIncludes> {
        return [
            {
                title: 'Actions',
                key: 'actions',
                sortable: false,
                fixed: true,
                width: 81,
                minWidth: '81',
                headerProps: { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } },
                cellProps() {
                    return { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } };
                },
            },
            {
                title: 'Inventory Code',
                key: 'inventory_code',
                link: (item: IndexInventoryIncludes) => useInventoryRepository().query.show(item.id).endpoint,
                fixed: true,
                width: 243.05,
                minWidth: '243.05',
                headerProps: { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } },
                cellProps() {
                    return { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } };
                },
            },
            {
                title: 'Property Code',
                key: 'property.data.property_code',
                link: (item: IndexInventoryIncludes) => usePropertyRepository().query.show(item.property_id).endpoint,
                sortable: false,
            },
            { title: 'Year', key: 'year' },
            { title: 'Inventory Type Name', key: 'inventory_type.data.name' },
            {
                title: 'Owner',
                key: 'owner.data.name',
                link: (item: IndexInventoryIncludes) => useOwnerRepository().query.show(item.owner.data.id).endpoint,
                sortable: false,
            },
            { title: 'Total Carbon (tCO2e)', key: 'inventory_view.data.carbon_inventory_tco2e', sortable: false, align: 'center' },
            { title: 'Carbon Stock (tCO2e)', key: 'inventory_view.data.carbon_stock_tco2e', sortable: false, align: 'center' },
            { title: 'Buffer Percentage', key: 'inventory_view.data.buffer_percentage', align: 'center' },
            { title: 'Carbon Buffer (tCO2e)', key: 'inventory_view.data.carbon_buffer_tco2e', sortable: false, align: 'center' },
            { title: 'Carbon Loss', key: 'penalty', align: 'center' },
            { title: 'Blocks (Count)', key: 'inventory_view.data.count_of_blocks', sortable: false, align: 'center' },
            { title: 'Stock allocated (tCO2e)', key: 'inventory_view.data.carbon_stock_allocated', sortable: false, align: 'center' },
            { title: 'Stock available (tCO2e)', key: 'inventory_view.data.carbon_stock_available', sortable: false, align: 'center' },
            { title: 'Priority', key: 'priority', align: 'center' },
            { title: 'Status', key: 'inventory_view.data.status', sortable: false },
            { title: 'Start Date', key: 'start_date', date: true, sortable: false },
            { title: 'End Date', key: 'end_date', date: true, sortable: false },
            { title: 'Active Start Date', key: 'interval_start_date', date: true, sortable: false },
            { title: 'Active End Date', key: 'interval_end_date', date: true, sortable: false },
            { title: 'Active Days', key: 'inventory_view.data.active_days', align: 'center', sortable: false },
            { title: 'Days in Year', key: 'inventory_view.data.days_in_year', align: 'center', sortable: false },
            { title: 'Fraction of Active Day', key: 'inventory_view.data.active_fraction', align: 'center', sortable: false },
            { title: 'Total Carbon Full Year (tCO2e)', key: 'inventory_view.data.total_carbon_full_year', align: 'center', sortable: false },
            { title: 'Created At', key: 'created_at' },
            { title: 'Updated At', key: 'updated_at' },
        ];
    }

    public static csvHeaders(): CsvStructure[] {
        return Inventory.headers()
            .filter((header) => header.key !== 'actions')
            .map((header) => ({ title: header.title, key: header.key })) as CsvStructure[];
    }

    public static override routes(): ReturnType<typeof routes> {
        return routes();
    }
}
