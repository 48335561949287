import { Model } from '@shared/models/Model';
import { useRoute } from '@shared/composables/useRoute';
import { type AllPropertyIncludes, type IndexPropertyIncludes, type IPropertyDefaultIncludes, routes, type Year } from '@app/land-hold/property/routes/routes';
import type { IndexInventoryPropertyIncludes } from '@app/land-hold/inventory/routes/routes';
import { useUtility } from '@shared/composables/useUtility';
import { Inventory } from '@app/land-hold/inventory/models/Inventory';
import type { CsvStructure } from '@shared/composables/useDownloadCsv';
import { usePropertyRepository } from '@app/land-hold/property/repositories/usePropertyRepository';
import { useOwnerRepository } from '@app/land-hold/owner/repositories/useOwnerRepository';
import { useStewardRepository } from '@app/land-hold/steward/repositories/useStewardRepository';
import type { ChipConfig, DataTableHeaders, DotNotationOf } from '@shared/types/Vuetify';
import type { MultiObjectInclude, ValuesOfConst } from '@shared/types/ApiResponse';
import type { IModel } from '@shared/types/Model';
import { Project, type ProjectApprovalStatus } from '@app/land-hold/project/models/Project.ts';

enum PartitionStatus {
    Empty = 'empty',
    Full = 'full',
    Partial = 'partial',
    Overflow = 'overflow',
}

type InventoryYear = Year & {
    haveInventory: boolean;
};

const { getYearRange, currentYear } = useUtility();

export interface IProperty extends IModel, IPropertyDefaultIncludes {
    airtable_id: string;
    owner_id: string;
    steward_id: string;
    state_id: string;
    inventory_type_id: string;
    airtable_owner_id: string;
    airtable_steward_id: string;
    airtable_state_id: string;
    property_name: string;
    community: string;
    municipality: string;
    property_area_ha: string;
    property_short_code: string;
    property_code: string;
    partitioned_area: string;
    unpartition_area: string;
    active_project_name: string | null;
    active_project_approval_status: ValuesOfConst<typeof ProjectApprovalStatus>;
    projects_count: number;
    start_date: string | null;
    end_date: string | null;
    priority: number;
    buffer: number | null;
    carbon_loss: number | null;
    total_carbon: number | null;
    total_carbon_stock: number | null;
    total_carbon_buffer: number | null;
    total_carbon_loss: number | null;
    total_buffer_carbon_loss_delta: number | null;
    additional_info: string | null;
    partition_status: PartitionStatus;
}

export class Property extends Model implements IProperty {
    airtable_id!: string;
    owner_id!: string;
    steward_id!: string;
    state_id!: string;
    inventory_type_id!: string;
    airtable_owner_id!: string;
    airtable_steward_id!: string;
    airtable_state_id!: string;
    property_name!: string;
    community!: string;
    municipality!: string;
    property_area_ha!: string;
    property_short_code!: string;
    property_code!: string;
    partitioned_area!: string;
    unpartition_area!: string;
    active_project_name!: string;
    active_project_approval_status!: ValuesOfConst<typeof ProjectApprovalStatus>;
    projects_count!: number;
    start_date!: string | null;
    end_date!: string | null;
    priority!: number;
    buffer!: number | null;
    carbon_loss!: number | null;
    total_carbon!: number | null;
    total_carbon_stock!: number | null;
    total_carbon_buffer!: number | null;
    total_carbon_loss!: number | null;
    total_buffer_carbon_loss_delta!: number | null;
    additional_info!: string | null;
    partition_status!: PartitionStatus;
    inventories_by_year!: MultiObjectInclude<Year>;

    public constructor(o: IProperty) {
        super(o);
        Object.assign(this, o);
    }

    public hidden(): (keyof AllPropertyIncludes)[] {
        return ['property_short_code', 'property_code', 'partitioned_area', 'unpartition_area', 'partition_status', 'owner', 'steward', 'state', 'inventories_view', 'inventories_by_year'];
    }

    public static override chips(): DotNotationOf<IProperty>[] {
        return ['partition_status', 'active_project_approval_status'];
    }

    public static override chipConfig(field: DotNotationOf<IProperty>, item: IProperty): ChipConfig {
        switch (field) {
            case 'active_project_approval_status':
                return {
                    size: 'small',
                    color: Project.getStatusColor(item.active_project_approval_status),
                    text: item.active_project_approval_status,
                };
            case 'partition_status':
                return { size: 'small', color: Property.getStatusColor(item.partition_status) };
            default:
                return { size: 'small' };
        }
    }

    public static getStatusColor(status: PartitionStatus): string {
        switch (status) {
            case PartitionStatus.Empty:
            case PartitionStatus.Overflow:
                return 'error';
            case PartitionStatus.Full:
                return 'success';
            case PartitionStatus.Partial:
                return 'warning';
            default:
                return 'info';
        }
    }

    public static override headers(): DataTableHeaders<IndexPropertyIncludes> {
        return [
            {
                title: 'Actions',
                key: 'actions',
                sortable: false,
                fixed: true,
                width: 153,
                minWidth: '153',
                headerProps: { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } },
                cellProps() {
                    // TODO: maybe we can just use "class: 'nameOfClass'" or something?
                    return { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } };
                },
            },
            {
                title: 'Property Code',
                key: 'property_code',
                link: (item: IndexPropertyIncludes) => usePropertyRepository().query.show(item.id).endpoint,
                fixed: true,
                width: 301,
                headerProps: { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } },
            },
            { title: 'Property Name', key: 'property_name' },
            { title: 'Partition Status', key: 'partition_status', sortable: false },
            { title: 'Approval Status', key: 'active_project_approval_status', sortable: false },
            { title: 'Current Year Project', key: 'active_project_name', sortable: false },
            { title: 'All Projects', key: 'projects_count', sortable: false },
            {
                title: 'Owner',
                key: 'owner.data.name',
                link: (item: IndexPropertyIncludes) => useOwnerRepository().query.show(item.owner_id).endpoint,
            },
            {
                title: 'Steward',
                key: 'steward.data.steward_code',
                link: (item: IndexPropertyIncludes) => useStewardRepository().query.show(item.steward_id).endpoint,
            },
            { title: 'State', key: 'state.data._3_letter_code' },
            { title: 'Community', key: 'community' },
            { title: 'Municipality', key: 'municipality' },
            { title: 'Area (ha)', key: 'property_area_ha', align: 'center' },
            { title: 'Short Code', key: 'property_short_code' },
            { title: 'Inventory Type', key: 'inventory_type.data.name' },
            { title: 'Partitioned Area (ha)', key: 'partitioned_area', sortable: false, align: 'center' },
            { title: 'Unpartitioned Area (ha)', key: 'unpartition_area', sortable: false, align: 'center' },
            { title: 'Start Date', key: 'start_date', date: true },
            { title: 'End Date', key: 'end_date', date: true },
            { title: 'Buffer Percentage', key: 'buffer', align: 'center' },
            { title: 'Carbon Loss', key: 'carbon_loss', align: 'center' },
            { title: 'Queue Priority', key: 'priority', align: 'center' },
            { title: 'Total Carbon', key: 'total_carbon', align: 'center', sortable: false },
            { title: 'Total Carbon Stock', key: 'total_carbon_stock', align: 'center', sortable: false },
            { title: 'Total Buffer', key: 'total_carbon_buffer', align: 'center', sortable: false },
            { title: 'Total Carbon Loss', key: 'total_carbon_loss', align: 'center', sortable: false },
            { title: 'Total Buffer vs Loss', key: 'total_buffer_carbon_loss_delta', align: 'center', sortable: false },
            { title: 'Created At', key: 'created_at' },
            { title: 'Updated At', key: 'updated_at' },
        ];
    }

    public static csvHeaders(): CsvStructure[] {
        return Property.headers()
            .filter((header) => header.key !== 'actions')
            .map((header) => ({ title: header.title, key: header.key })) as CsvStructure[];
    }

    public static manageInventoryPageHeaders(): DataTableHeaders<IndexInventoryPropertyIncludes> {
        return [
            { title: '', key: 'data-table-select', sortable: false },
            { title: '', key: 'data-table-expand', sortable: false },
            {
                title: 'Property Code',
                key: 'property_code',
                link: (item) => useRoute().build(Inventory.routes().management.properties.show, { id: item.id }),
            },
            { title: 'Property Name', key: 'property_name' },
            {
                title: 'Inventory By Year',
                key: 'inventories_by_year.data',
                sortable: false,
                value(item: IndexInventoryPropertyIncludes) {
                    return (
                        mergeYears(item.inventories_by_year.data)
                            .map((year: InventoryYear) => yearHTML(year))
                            .join(' ') || null
                    );
                },
                renderAsHtml: true,
            },
            {
                title: 'Owner',
                key: 'owner.data.name',
                link: (item) => useOwnerRepository().query.show(item.owner_id).endpoint,
            },
            {
                title: 'Steward',
                key: 'steward.data.steward_code',
                link: (item) => useStewardRepository().query.show(item.steward_id).endpoint,
            },
            { title: 'State', key: 'state.data._3_letter_code' },
            { title: 'Community', key: 'community' },
            { title: 'Municipality', key: 'municipality' },
            { title: 'Area (ha)', key: 'property_area_ha', align: 'center' },
            { title: 'Short Code', key: 'property_short_code' },
            { title: 'Inventory Type Name', key: 'inventory_type.data.name' },
            { title: 'Partitioned Area (ha)', key: 'partitioned_area', sortable: false, align: 'center' },
            { title: 'Unpartitioned Area (ha)', key: 'unpartition_area', sortable: false, align: 'center' },
            { title: 'Partition Status', key: 'partition_status', sortable: false },
            { title: 'Start Date', key: 'start_date', date: true },
            { title: 'End Date', key: 'end_date', date: true },
            {
                title: 'Queue Priority',
                key: 'priority',
                align: 'center',
                value(item: IndexInventoryPropertyIncludes) {
                    return item.priority || 'none';
                },
            },
            { title: 'Total Carbon', key: 'total_carbon', align: 'center', sortable: false },
            { title: 'Total Carbon Stock', key: 'total_carbon_stock', align: 'center', sortable: false },
            { title: 'Total Buffer', key: 'total_carbon_buffer', align: 'center', sortable: false },
            { title: 'Total Carbon Loss', key: 'total_carbon_loss', align: 'center', sortable: false },
            { title: 'Total Buffer vs Loss', key: 'total_buffer_carbon_loss_delta', align: 'center', sortable: false },
        ];
    }

    public static override routes(): ReturnType<typeof routes> {
        return routes();
    }
}

function mergeYears(yearsWithInventory: Year[]): InventoryYear[] {
    const from = 2020;
    const possibleYears = getYearRange(from, currentYear() + 1);
    return possibleYears.map((year) => {
        const inventoryYear = yearsWithInventory.find((inventoryYear) => inventoryYear.year === year);
        return {
            year: parseInt(year.toString().slice(2)),
            haveInventory: !!inventoryYear,
        };
    });
}

function yearHTML(year: InventoryYear): string {
    const chipColor = year.haveInventory ? 'text-success' : 'text-error';
    return `
    <span class="v-chip v-theme--defaultLight ${chipColor} v-chip--density-default v-chip--rounded v-chip--size-small v-chip--variant-tonal" draggable="false">
        <span class="v-chip__underlay">
            </span>
            <div class="v-chip__content" data-no-activator="">${year.year.toString()}</div>
        </span>
    </span>`;
}
