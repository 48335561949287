import type { GeneralConfig } from '@shared/types/Config';

export const inventoryConfig: GeneralConfig = {
    sortFieldMap: {
        'inventory_view.data.carbon_inventory_tco2e': 'inventories:id,inventory_id|carbon_inventory_tco2e',
        'inventory_view.data.carbon_stock_tco2e': 'inventories:id,inventory_id|carbon_stock_tco2e',
        'inventory_view.data.carbon_buffer_tco2e': 'inventories:id,inventory_id|carbon_buffer_tco2e',
        'inventory_view.data.count_of_blocks': 'inventories:id,inventory_id|count_of_blocks',
        'inventory_view.data.carbon_stock_allocated': 'inventories:id,inventory_id|carbon_stock_allocated',
        'inventory_view.data.carbon_stock_available': 'inventories:id,inventory_id|carbon_stock_available',
        'inventory_view.data.status': 'inventories:id,inventory_id|status',
        'inventory_view.data.buffer_percentage': 'inventories.buffer_percentage',
        'penalty': 'inventories.penalty',
        'inventory_type.data.name': 'inventory_types|name',
        priority: 'properties:property_id,id|priority',
    },
};
